import React, {useEffect, useState} from "react"
import {InlineShareButtons} from 'sharethis-reactjs';

const ShareButtons = () => {
  return (
    <>
    
    {/* <InlineShareButtons           //https://sharethis.com/support/installation/how-to-install-sharethis-tools-on-reactjs/
          config={{
            alignment: 'left',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'null',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'email',
              'facebook',
              'linkedin',
              'twitter'
            ],
            padding: 10,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 40              // the size of each button (INTEGER)
          }}
        /> */}
    </>
  )
}

export default ShareButtons
