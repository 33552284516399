import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import AboutSubmenu from "../../components/template-partials/about/about-submenu"

import { UpNextCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/1.0_backgroundheader.jpg"
import headingRound from "../../images/about-and-research-nav-image.jpg"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import randomizedControlled from "../../images/1.3_randomizedcontroltrial.png"
import randomizedTrial from "../../images/1.3_nonrandomizedtrial.png"
import singleVMulti from "../../images/single-v-multin.png"
import study from "../../images/1.4-study.jpg"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import VideoPlayer from "../../components/VideoPlayer"

const UnderStandingTrialsPage = () => {
  return (
    <Layout>
      <SEO
        title="Understanding Neuroblastoma Clinical Trial Results"
        description="Gain a better understanding of how neuroblastoma clinical trials work and how to interpret the results with an overview of important terms and information."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Understanding Clinical Trials"
      >
        <h1 className="section__heading section__heading--green h1">
          Understanding Clinical Trials
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`About Neuroblastoma &amp; Research`}>
              <AboutSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">
                  Interpreting clinical trial results
                </h2>
                <p>
                  A{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#clinical-trials"
                  >
                    clinical trial
                  </Link>{" "}
                  is also known as a study. You may have questions about how
                  studies are organized and what the study results mean for a
                  certain treatment.
                </p>
                <p>
                  One of the most reliable ways to show if a treatment works is
                  to test it in a{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#randomized-controlled-trial"
                  >
                    randomized controlled clinical trial
                  </Link>
                  . Here you’ll find information about why this study method is
                  so reliable, as well as other helpful information about
                  clinical studies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              What is a randomized controlled clinical trial?
            </h3>
            <p>
              In a{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#randomized-controlled-trial"
              >
                randomized controlled clinical trial
              </Link>
              , the participants are divided by chance (randomized) into
              different groups (
              <Link
                rel="glossary"
                to="/resources/glossary/#treatment-arm"
              >
                treatment arms
              </Link>
              ). Each group receives a different treatment. The results from
              each group are then compared. The trial may test 2 or more
              therapies at the same time, so it's possible to compare their
              effectiveness and safety. A nonrandomized controlled trial gives
              all patients the same therapy, so the results cannot be compared
              with another therapy.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-4 lg_mb_4">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med text-center">
              Randomized controlled trial
            </h4>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 lg_mb_2">
          <div className="columns text-center">
            <img
              src={randomizedControlled}
              alt="Randomized controlled clinical trials divide participants by chance into different groups."
            />
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-4 lg_mb_4">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med text-center">
              Nonrandomized controlled trial
            </h4>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 lg_mb_2">
          <div className="columns text-center">
            <img
              src={randomizedTrial}
              alt="Nonrandomized controlled clinical trials give all participants the same treatment."
            />
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Why is it important to compare treatments?
            </h3>
            <p>
              A randomized controlled trial that compares treatment results is
              the preferred way to do a clinical trial because it aims to reduce
              the chance of{" "}
              <Link rel="glossary" to="/resources/glossary/#bias">
                bias
              </Link>{" "}
              for or against a treatment. This type of study means that every
              child has the same chance of being in a treatment arm and it helps
              the researchers compare the results fairly.
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner align-middle">
          <div className="columns large-4 small-12 text-center large-text-left">
            <FancyImage
              url={study}
              alt="Does it matter which patients are included in a study?"
            />
          </div>
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Does it matter which patients are included in a study?
            </h3>
            <p>
              Each study has guidelines about which patients can be in the
              study. Patients in a study can be different ages. They may have
              different weights and heights. They may be male or female or both.
              However, all the patients should have the same type or{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#disease-stage"
              >
                stage of disease
              </Link>{" "}
              and they should all have had the same or similar treatments before
              the study starts. This helps the researchers compare the results
              of the treatments in the study more effectively.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Does it matter how many patients are included in a study?
            </h3>
            <p>
              Generally, the more people enrolled in a study, the more reliable
              the results will be. The number of patients enrolled in a study is
              known as the{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#sample-size"
              >
                sample size
              </Link>
              . A large sample size lets researchers more clearly see a
              difference between the treatment results.
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Where do study patients come from?{" "}
            </h3>
            <p>
              Clinical studies can enroll patients from multiple institutions,
              such as hospitals, cancer care centers, or cancer clinics, or from
              a{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#single-or-limited-institution"
              >
                single institution
              </Link>
              . Multiple-institution studies are preferred because they help
              show if the results are the same across more patients in many
              different places in the United States and/or around the world.
            </p>
          </div>
          <div className="columns large-4 small-12">
            <img
              src={singleVMulti}
              alt="Neuroblastoma clinical trials can enroll patients from multiple institutions or a single institution."
            />
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              How was the study treatment evaluated?{" "}
            </h3>
            <p>
              <Link
                rel="glossary"
                to="/resources/glossary/#randomized-controlled-trial"
              >
                Randomized controlled trials
              </Link>{" "}
              may use different outcome measures to evaluate how well a new
              treatment works.{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#event-free-survival-or-efs"
              >
                Event-free survival (EFS)
              </Link>
              ,{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#overall-survival-or-os"
              >
                overall survival (OS)
              </Link>
              , and{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#objective-response-or-or"
              >
                objective response (OR)
              </Link>{" "}
              are all different ways that studies can measure how well a therapy
              is working. It is important to avoid comparing studies that use
              different outcome measures. You can read more about each below.
            </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-3 align-center">
          <div className="columns small-11 large-4">
            <div
              className="card card--comparison"
              style={{ borderColor: SassVars.viridian }}
            >
              <div
                className="card__angle-head"
                style={{ background: SassVars.amber }}
              >
                <div className="text-center">
                  <h3 className="jost-bold h1--xl">EFS</h3>
                  <p className="h5 jost-med">Event-free survival</p>
                </div>
              </div>
              <h4 className="h4 jost-med tc-viridian">What it measures</h4>
              <p className="p--std">
                One of the most important measures of EFS is how long the
                patient stays in remission. For example, if a study reports a
                3-year EFS of 50%, it means that 3 years after their study
                treatment began, 50% of the patients are alive and in{" "}
                <Link
                  rel="glossary"
                  to="/resources/glossary/#remission"
                >
                  remission
                </Link>
                .
              </p>
              <h4 className="h4 jost-med tc-viridian lg_mt-2">
                Why it's important
              </h4>
              <ul className="ul--dotted">
                <li>
                  EFS shows how well the therapy can keep the patient in
                  remission, meaning the cancer can no longer be detected
                </li>
              </ul>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div
              className="card card--comparison"
              style={{ borderColor: SassVars.viridian }}
            >
              <div
                className="card__angle-head"
                style={{ background: SassVars.barney }}
              >
                <div className="text-center">
                  <h3 className="jost-bold h1--xl tc-white">OS</h3>
                  <p className="h5 jost-med tc-white">Overall survival</p>
                </div>
              </div>
              <h4 className="h4 jost-med tc-viridian">What it measures</h4>
              <p className="p--std">
                The length of time, after either the date of a cancer diagnosis
                or the start of treatment, that patients are still alive. For
                example, if a study reports a 5-year OS of 70%, it means that
                after 5 years, 70% of the patients are still alive.
              </p>
              <h4 className="h4 jost-med tc-viridian lg_mt-2">
                Why it's important
              </h4>
              <ul className="ul--dotted">
                <li>
                  Unlike EFS, OS percentages show how many patients are alive
                  after therapy; however, patients could have relapsed or
                  received additional treatments during that time
                </li>
              </ul>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div
              className="card card--comparison"
              style={{ borderColor: SassVars.viridian }}
            >
              <div
                className="card__angle-head"
                style={{ background: SassVars.midGrey }}
              >
                <div className="text-center">
                  <h3 className="jost-bold h1--xl tc-white">OR</h3>
                  <p className="h5 jost-med tc-white">Objective response</p>
                </div>
              </div>
              <h4 className="h4 jost-med tc-viridian">What it measures</h4>
              <p className="p--std">
                An objective response means a child had either a complete or
                partial response to the treatments they received.
              </p>
              <h4 className="h4 jost-med tc-viridian lg_mt-2">
                Why it's important
              </h4>
              <ul className="ul--dotted">
                <li>
                  A{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#complete-response-or-cr"
                  >
                    complete response (CR)
                  </Link>{" "}
                  is the disappearance of all signs of cancer in response to
                  treatment. However, this does not always mean the cancer has
                  been cured
                </li>
                <li>
                  A{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#partial-response-or-pr"
                  >
                    partial response (PR)
                  </Link>{" "}
                  is a decrease in the size of the tumor or the extent of cancer
                  in the body
                </li>
              </ul>
            </div>
          </div>
        </div>

        <InlineInfocard
          className="lg_mt-3 mt-2"
          text="Understanding information about clinical trials may be difficult. You can find information from many sources, especially on the internet. However, some of the information may be misinformation. Ask your doctor to help you interpret the information so that you can make the right treatment decision for your child."
        />
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Understanding Clinical Trials
            </h3>
            <p>
              Hear from a pediatric oncologist discussing some of the confusing
              topics around understanding clinical trials and interpreting their
              results.
            </p>
            <VideoPlayer
              embedId="761928269"
              title="Understanding Clinical Trials"
            />
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard
          topic="Neuroblastoma Research"
          title="Want to understand the history of neuroblastoma research?"
          lead="Learn about the groups that conduct clinical trials and about the advances made in treatment through summaries of past clinical trials."
          url="/what-is-neuroblastoma/research/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default UnderStandingTrialsPage
